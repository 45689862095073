
































































































































































































































.gradient {
  background: rgb(231,232,233);
  background: linear-gradient(180deg, rgba(231,232,233,1) 0%, rgba(255,255,255,1) 100%);
}
.inverse-gradient {
  background: rgb(231,232,233);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%,rgba(231,232,233,1) 100%);
}
.rectangle {
  background-color: white;
  text-align: center;
  width: 400px;
  margin: 20px;
  position: relative;
  padding: 10px;
  .caption {
    position: absolute;
    left: 0;
    transform: translate(0, -50%);
    top: 50%;
    margin: auto 20px;
  }
  .text-subtitle1 {
    line-height: 1.5;
  }
}
.inner-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #757575 ;
  border-radius: 5px;
  height: 400px;
}
.bordered {
  border: 1px solid #757575 ;
  border-radius: 5px;
}
.cta-button {
  text-align: center;
  transform: translate(-50%, -50%);
  left: 50%;
}
.section.boxed {
  display: flex;
  > div {
    width: 90vw;
    margin: auto;
  }
}
.video-container {
  max-width: 800px;
  .rwd-video {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
    iframe, object, embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
  }
}
